<template>
	<div class="main infoSet">
		
		<div class="tit"><span>安全设置</span></div>
		<div class="items">
			
			<div class="item">
				<div class="left">
					<img src="../../assets/images/common/i_tel.jpg" alt="" class="ico">
					<div class="txts">
						<p class="p1">手机绑定</p>
						<p class="p2"><span class="c8">已绑定手机号：</span><span>{{logins.loginInfo.phone}}</span></p>
					</div>
				</div>
				<div class="right">
					<router-link to="/person/infoset/bindtel" class="btn" >更改手机号</router-link>
				</div>
			</div>
			
			<div class="item">
				<div class="left">
					<img src="../../assets/images/common/i_email.jpg" alt="" class="ico">
					<div class="txts">
						<p class="p1">邮箱绑定</p>
						<p class="p2">
							{{logins.loginInfo.email==''?'暂未绑定':logins.loginInfo.email}}
							
							</p>
					</div>
				</div>
				<div class="right">
					<router-link to="/person/infoset/modifyemail" class="btn" >
						{{logins.loginInfo.email==''?'绑定邮箱':'更改邮箱'}}
					</router-link>
				</div>
			</div>
			
			<div class="item">
				<div class="left">
					<img src="../../assets/images/common/i_password.jpg" alt="" class="ico">
					<div class="txts">
						<p class="p1">密码修改</p>
						<p class="p2">请不少于8位的密码，需包含字母数字</p>
					</div>
				</div>
				<div class="right">
					<router-link to="/person/infoset/modifypassword" class="btn" >修改密码</router-link>
				</div>
			</div>
			
		</div>
		
		
	</div>
</template>
<script type="text/javascript">
	import useStore from '@/store/index.js'
	export default {
		data(){
			return {
				logins:{}
			}
		},
		created(){
			this.store = useStore();
			this.logins = this.store.logins;
		},
		components:{
		}
	}
</script>
<style type="text/css" scoped="">
	.main{flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;}
	
	.infoSet .item{border-bottom:#EDEEEF 1px solid;display: flex;justify-content: space-between;padding: 54px 28px 44px;}
	.infoSet .item .ico{width: 54px;height: 54px;border-radius: 100%;margin-right: 34px;;}
	.infoSet .txts{display: inline-block;vertical-align: middle;}
	.infoSet .txts .p1{font-size: 16px;color: #888888;}
	.infoSet .txts .c8{color: #888888;}
	.infoSet .txts .p2{margin-top: 16px;font-size: 14px;color: #222222;}
	.infoSet .btn{width: 120px;height: 38px;border-radius: 2px;border: 1px solid #F5630B;font-size: 14px;color: #EA5504;line-height: 38px;text-align: center;margin-top: 10px;}
</style>